export default {
  icon: ' mdi-cart',
  text: 'Vendas',
  permissions: [
    { name: 'salesIndex', text: 'Visualizar Vendas', value: 0 },
    { name: 'salesCreateEdit', text: 'Criar/Editar Vendas', value: 0 },
    { name: 'salesCancel', text: 'Cancelar Vendas', value: 0 },
    { name: 'salesOtherUser', text: 'Vender em nome de outro usuário', value: 0 },
    { name: 'userAproveSale', text: 'Aprovar vendas', value: 0 },
  ]

}